<template>
<div class="p-fluid" style="margin-top: 10px">
    <div class="p-grid">
        <div class="p-col-6">
            <label for="tourguide_name">Tour guide name:</label>
            <AutoComplete v-model="selectedTourGuide" :suggestions="filteredTourGuide" @complete="searchTourGuide($event)" @item-select="onTourGuideNameSelect($event)" field="guide_name" :class="{'p-invalid':v_bookingguide$.tourguide_id.$error}" />
        </div>
        <div class="p-col-6">
            <label for="language">Language:</label>
            <Dropdown v-model="selectedLanguage" :options="guideLanguage" @change="onLanguageChange()" optionLabel="language" :class="{'p-invalid':v_bookingguide$.language_id.$error}"></Dropdown>
        </div>
    </div>
    <div class="p-grid" style="align-items: flex-end;">
        <div class="p-col">
            <label for="book_from">From date:</label>
            <Calendar id="book_from" v-model="tourguideBookingForm.from_date" :monthNavigator="true" :yearNavigator="true" yearRange="2010:2040" dateFormat="dd-M-yy" style="height: 2.15rem;" :class="{'p-invalid':v_bookingguide$.from_date.$error || !isBookingDateValid}"/>
        </div>
        <div class="p-col">
            <label for="book_to">To date:</label>
            <Calendar id="book_to" v-model="tourguideBookingForm.to_date" :monthNavigator="true" :yearNavigator="true" yearRange="2010:2040" dateFormat="dd-M-yy" style="height: 2.15rem;" :class="{'p-invalid':v_bookingguide$.to_date.$error || !isBookingDateValid}"/>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col" style="padding-top: 15px; align-self: flex-end">
            <Button v-if="!isEditTourGuideBooking" label="Save Tour Guide Booking" @click="saveTourGuideBooking()" class="p-button-success p-button-sm" style="width: 165px;"></Button>
            <Button v-if="isEditTourGuideBooking" label="Update Tour Guide Booking" @click="updateTourGuideBooking()" class="p-button-success p-button-sm p-mr-1" style="width: 182px" />
        </div>
    </div>
</div>
</template>

<script>
import { ref, reactive, computed } from 'vue'
import { useToast } from "primevue/usetoast";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import BookingService from '../service/BookingService';
import TourGuideService from '../service/TourGuideService';

export default {
    props: {
        BookingID: Number
    },
    emits: ['RefreshTourGuideBooking'],
    setup(props, { emit }) {
        const toast = useToast()
        const selectedTourGuide = ref()
        const filteredTourGuide = ref()
        let tourguideID = ref(0)
        
        let guideLanguage = ref([])
        let selectedLanguage = ref()

        let tourguideBookingForm = reactive({})
        let isEditTourGuideBooking = ref(false)
        let isBookingDateValid = ref(true)

        const bookingService = ref(new BookingService())
        const tourguideService = ref(new TourGuideService())

        //=============Method=============
        const searchTourGuide = (event) => {
            setTimeout(() => {
                if (event.query.trim().length) {
                    tourguideService.value.searchTourGuide(event.query.toLowerCase()).then(data => {
                        if(!data.errorResponse) {
                            if(data.length > 0) {
                                filteredTourGuide.value = data
                            } else {
                                filteredTourGuide.value = [{guide_name: 'No result'}]
                                guideLanguage.value.length = 0
                            }
                        }
                    })
                }
            }, 400);
        }

        const onTourGuideNameSelect = (event) => {
            if(event.value.id) {
                tourguideID.value = event.value.id
                tourguideBookingForm.tourguide_id = tourguideID.value
                getGuideLanguage(tourguideID.value)
                tourguideBookingForm.language_id = ''
            } else {
                selectedTourGuide.value = null
                tourguideBookingForm.tourguide_id = ''
                tourguideBookingForm.language_id = ''
            }
        }

        const getGuideLanguage = (guide_id) => {
            if (guide_id != 0) {
                tourguideService.value.getLanguage(guide_id).then(data => {
                    if(!data.errorResponse) {
                        guideLanguage.value = data
                    }

                    if(tourguideBookingForm.language_id != '') {
                        selectedLanguage.value = guideLanguage.value.find(item => item.language_id === tourguideBookingForm.language_id)
                    }
                })
            }
        }

        const onLanguageChange = () => {
            tourguideBookingForm.language_id = selectedLanguage.value.language_id
        }

        const initTourGuideBookingForm = () => {
            const tourguide_booking_form = {
                id: '',
                from_date: '',
                to_date: '',
                tourguide_id: '',
                language_id: '',
                booking_id: '',
            }

            selectedTourGuide.value = null
            selectedLanguage.value = null

            Object.assign(tourguideBookingForm, tourguide_booking_form)
        }

        const saveTourGuideBooking = () => {
            if(validateForm() && validateBookingDate() && props.BookingID != 0) {
                tourguideBookingForm.booking_id = props.BookingID
                bookingService.value.addBookingTourGuide(tourguideBookingForm).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            isBookingDateValid.value = false
                            toast.add({severity:'warn', summary: 'Save Error', detail: data.message, life: 4500}); 
                        } else {
                            initTourGuideBookingForm()
                            filteredTourGuide.value.length = 0
                            v_bookingguide$.value.$reset();
                            isBookingDateValid.value = true
                            emit('RefreshTourGuideBooking', props.BookingID)
                            toast.add({severity:'success', summary: 'Success', detail:'Tour guide booking has been created.', life: 3000}); 
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 4500});        
                    }
                })
            }
        }

        const editTourGuideBooking = (emitData) => {
            isEditTourGuideBooking.value = true
            initTourGuideBookingForm()
            
            Object.keys(tourguideBookingForm).forEach((key)=>{
                tourguideBookingForm[key] = emitData[key]
            })
            
            tourguideBookingForm.from_date = new Date(tourguideBookingForm.from_date)
            tourguideBookingForm.to_date = new Date(tourguideBookingForm.to_date)
            
            selectedTourGuide.value = emitData.first_name + ' ' + emitData.last_name
            getGuideLanguage(emitData.tourguide_id)
        }

        const updateTourGuideBooking = () => {
            if(validateForm() && validateBookingDate() && props.BookingID != 0) {              
                bookingService.value.updateBookingTourGuide(tourguideBookingForm.id, tourguideBookingForm).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            isBookingDateValid.value = false
                            toast.add({severity:'warn', summary: 'Update Error', detail: data.message, life: 4500}); 
                        } else {
                            v_bookingguide$.value.$reset();
                            emit('RefreshTourGuideBooking', props.BookingID)
                            isBookingDateValid.value = true
                            toast.add({severity:'success', summary: 'Success', detail:'Tour guide booking has been updated.', life: 3000});        
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 4500});
                    }
                })
            }
        }

        //=================Form Validation=================
        const bookingTourGuideRules = computed(() => {
            return {
                from_date: { required },
                to_date: { required },
                tourguide_id: { required },
                language_id: { required },
            }
        })
        const v_bookingguide$ = useVuelidate(bookingTourGuideRules, tourguideBookingForm)
        const validateForm = () => {
            v_bookingguide$.value.$validate();
            if(!v_bookingguide$.value.$error){
                return true
            } else {
                return false
            }
        }

        const validateBookingDate = () => {
            const booking_from = new Date(tourguideBookingForm.from_date)
            const booking_to = new Date(tourguideBookingForm.to_date)
            if(booking_from >= booking_to) {
                isBookingDateValid.value = false
                toast.add({severity:'warn', summary: 'Error', detail: 'Selected date is invalid.', life: 4000}); 
                return false
            } else {
                isBookingDateValid.value = true
                return true
            }
        }

        return {
            selectedTourGuide,
            filteredTourGuide,
            searchTourGuide,
            onTourGuideNameSelect,
            saveTourGuideBooking,

            guideLanguage,
            selectedLanguage,
            onLanguageChange,

            v_bookingguide$,
            isBookingDateValid,
            tourguideBookingForm,
            editTourGuideBooking,
            isEditTourGuideBooking,
            updateTourGuideBooking,
        }
    },
}
</script>

<style lang="scss" scoped>

</style>