<template>
    <div class="loading-screen">
        <div class="loading-progress">
          <span style="font-size: 2rem; padding: .5rem 1rem; color:var(--orange-500)" class="pi pi-exclamation-triangle"></span>
        </div>
          <h5 style="margin: 0">There was an error while retrieving data, {{ ErrorCode }}.</h5>
    </div>
</template>

<script>
export default {
    props: {
        ErrorCode: Number
    },
    setup() {
        
    },
}
</script>

<style lang="scss" scoped>
.loading-screen {
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
    height: 100%;;
}
.loading-progress {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>