<template>
<div class="booking-tourguide">
    <div style="margin-top: 3px; height: 310px">
        <LoadingDataError v-if="loadingError.isError" :ErrorCode="loadingError.errorCode" />

        <DataTable
            :value="tourguideBooking"
            responsiveLayout="scroll"
            scrollHeight="280px"
            v-model:selection="selectedTourguideBooking" 
            selectionMode="single"
            dataKey="id"
            v-if="!loadingError.isError"
        >
            <!-- <Column field="id" header="ID" :style="{'min-width':'30px'}"></Column> -->
            <Column field="first_name" header="First name" :style="{'min-width':'130px'}"></Column>
            <Column field="last_name" header="Last name" :style="{'min-width':'130px'}"></Column>
            <Column field="from_date" header="From" :style="{'min-width':'105px'}">
                <template #body="{data}">
                    {{data.from_date != null ? dayjs(data.from_date).format('DD-MMM-YYYY') : ''}}
                </template>
            </Column>
            <Column field="to_date" header="To" :style="{'min-width':'105px'}">
                <template #body="{data}">
                    {{data.to_date != null ? dayjs(data.to_date).format('DD-MMM-YYYY') : ''}}
                </template>
            </Column>
            <Column field="guidingarea" header="Area" :style="{'min-width':'120px'}"></Column>
            <Column field="language" header="Language" :style="{'min-width':'90px'}"></Column>
            <Column field="phone1" header="Contact" :style="{'min-width':'120px'}"></Column>

            <Column :exportable="false" :style="{'min-width':'75px', 'width':'75px', 'padding':'0.4rem 0rem', 'text-align':'right'}">
                <template #body="slotProps">
                    <Button v-if="can('update', 'booking')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="onEditTourGuideBooking(slotProps.data)" />
                    <Button v-if="can('delete', 'booking')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteTourGuideBooking(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>

    <hr style="border: 0; border-top: 1px solid var(--surface-d);"/>
    <div style="display: flex; align-self: flex-end; margin-bottom: 10px">
        <Button v-if="can('create', 'booking')" label="Add Tour Guide Booking" class="p-button p-button-sm" @click="openBookingTourGuideForm()" :disabled="bookingID == 0 || loadingError.isError" />
        <Dialog v-model:visible="isBookingTourguideFormShow" header="Tour Guide Booking" :style="{width: '53rem', margin: '0px 5px'}" :modal="true">
            <BookingTourGuideForm ref="BookingTourGuideFormRef" :BookingID="bookingID" @RefreshTourGuideBooking="getTourGuideBooking" />
        </Dialog>
    </div>
</div>
</template>

<script>
import { ref, reactive, nextTick } from 'vue';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import BookingService from "../service/BookingService"
import LoadingDataError from '../components/LoadingDataError.vue';
import BookingTourGuideForm from '../components/BookingTourGuideForm.vue';
import dayjs from 'dayjs';

export default {
    setup() {
        const confirm = useConfirm();
        const toast = useToast();
        const { can } = useAbility()
        let tourguideBooking = ref([])
        let selectedTourguideBooking = ref()
        let loadingError = reactive({isError: false, errorCode: ''})
        const isBookingTourguideFormShow = ref(false)
        let bookingID = ref(0)
        const BookingTourGuideFormRef = ref()

        const bookingService = ref(new BookingService())

        //=============Method=============
        const getTourGuideBooking = (Booking_ID) => {
            if(Booking_ID != 0) {
                //SEND BOOKING_ID TO TOUR GUIDE BOOKING FORM'S PROPS
                bookingID.value = Booking_ID

                tourguideBooking.value.length = 0;
                selectedTourguideBooking.value = null

                bookingService.value.getBookingTourGuide(Booking_ID).then(data => {
                    if(!data.errorResponse) {
                        tourguideBooking.value = data

                        loadingError.isError = false
                    } else {
                        loadingError.isError = true
                        loadingError.errorCode = data.status
                    }
                })
            }
        }

        const openBookingTourGuideForm = () => {
            isBookingTourguideFormShow.value = true
        }

        const onEditTourGuideBooking = (data) => {
            selectedTourguideBooking.value = data
            
            isBookingTourguideFormShow.value = true
            nextTick(() => {
                BookingTourGuideFormRef.value.editTourGuideBooking(selectedTourguideBooking.value)
            })
        }

        const confirmDeleteTourGuideBooking = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.first_name + ' ' + data.last_name +'" booking?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const bookingTourGuideID = data.id
                    bookingService.value.deleteBookingTourGuide(bookingTourGuideID).then((data) => {
                        if(!data.errorResponse){
                            tourguideBooking.value = tourguideBooking.value.filter(val => val.id !== bookingTourGuideID);
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 4500});
                        }
                    })
                }
            });
        }

        return {
            can,
            loadingError,
            tourguideBooking,
            selectedTourguideBooking,
            isBookingTourguideFormShow,
            getTourGuideBooking,
            bookingID,

            openBookingTourGuideForm,
            BookingTourGuideFormRef,
            onEditTourGuideBooking,
            confirmDeleteTourGuideBooking,
            dayjs,
        }
    },
    components: {
        LoadingDataError,
        BookingTourGuideForm,
    }
}
</script>

<style lang="scss" scoped>

</style>